<!-- This example requires Tailwind CSS v2.0+ -->
<template>
   <div>
      <div class="flex flex-col">
         <div class="-my-2 sm:-mx-6 lg:-mx-8">
            <nav
               class="
               flex
               mb-8
               justify-between
               sm:px-6
               lg:px-8
               flex-wrap
               gap-y-4
               lg:h-10
               md:h-10
               "
               aria-label="Breadcrumb"
            >
               <ol class="flex items-center space-x-4">
                  <li>
                     <div>
                        <a
                           href="#"
                           class="text-gray-400 hover:text-gray-500"
                        >
                           <HomeIcon
                              class="flex-shrink-0 h-5 w-5"
                              aria-hidden="true"
                           />
                           <span class="sr-only">Home</span>
                           </a>
                     </div>
                  </li>
                  <li
                     v-for="page in pages"
                     :key="page.name"
                  >
                     <div class="flex items-center">
                        <ChevronRightIcon
                           class="flex-shrink-0 h-5 w-5 text-gray-400"
                           aria-hidden="true"
                        />
                        <a
                           :href="page.href"
                           class="
                           ml-4
                           text-sm
                           font-medium
                           text-gray-500
                           hover:text-gray-700
                           "
                           :aria-current="page.current ? 'page' : undefined"
                        >{{ page.name }}</a>
                     </div>
                     </li>
               </ol>
               <div class="flex flex-wrap gap-4 items-center">
                  <div class="relative rounded-md shadow-sm">
                     <div class="
                        absolute
                        inset-y-0
                        left-0
                        pl-3
                        flex
                        items-center
                        pointer-events-none
                        ">
                        <SearchIcon
                           class="h-5 w-5 text-gray-400"
                           aria-hidden="true"
                        />
                     </div>
                     <input
                        type="text"
                        name="email"
                        @input="debouncedQuery"
                        v-model="search"
                        class="
                        focus:ring-blue-500 focus:border-blue-500
                        block
                        w-full
                        pl-10
                        sm:text-sm
                        border-gray-300
                        rounded-md
                        "
                        placeholder="Search for users"
                     />
                  </div>
                  <button
                     @click="open=true"
                     type="button"
                     class=" inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-light-blue-600 hover:bg-light-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500 "
                  >
                     <FolderAddIcon
                        class="mr-3 h-5 w-5"
                        aria-hidden="true"
                     /> Create new user
                     </button>
               </div>
               </nav>
               <div v-if="client.count > 0"
                  class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
               >
                  <div class="
                  shadow
                  overflow-hidden
                  border-b border-gray-200
                  sm:rounded-tl-lg sm:rounded-tr-lg
                  ">
                     <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                           <tr>
                              <th
                                 scope="col"
                                 class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                              "
                              >
                                 Client Name
                                 </th>
                                 <th
                                    scope="col"
                                    class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                              "
                                 >
                                    Phone Number
                                    </th>
                                    <th
                                       scope="col"
                                       class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                              "
                                    >
                                       Case
                                       </th>
                                       <th
                                          scope="col"
                                          class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                              "
                                       >
                                          Actions
                                          </th>
                                          <th
                                             scope="col"
                                             class="relative px-6 py-3"
                                          >
                                             <span class="sr-only">Edit</span>
                                             </th>
                           </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                           <tr
                              :class="i % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                              v-for="(client, i) in client.items"
                              :key="client.email"
                           >
                              <td class="px-6 py-4 whitespace-nowrap">
                                 <div class="flex items-center">
                                    <div class="flex-shrink-0 h-10 w-10">
                                       <img
                                          class="h-10 w-10 rounded-full"
                                          :src="`https://source.boringavatars.com/beam/120/${client.email}?colors=0C4A6E,0284C7,D1FAE5,0284C7,e76f51`"
                                          alt=""
                                       />
                                    </div>
                                    <div class="ml-4">
                                       <div class="text-sm font-medium text-gray-900">
                                          {{ client.info?.first_name }} {{ client.info?.last_name }}
                                       </div>
                                       <div class="text-sm text-gray-500">
                                          {{ client.email }}
                                       </div>
                                    </div>
                                 </div>
                              </td>
                              <td class="px-6 py-4 whitespace-nowrap">
                                 <div class="text-sm text-gray-500">
                                    {{ client.info?.phone }}
                                 </div>
                              </td>
                              <td class="px-6 py-4 whitespace-nowrap grid gap-2">
                                 <template
                                    :key="cases.id"
                                    v-for="cases in client.cases"
                                 >
                                    <router-link
                                       :to="{ name: 'case', params: { id: cases.id } }"
                                       :class="
                                    cases.info.app_type.uid === 'sale'
                                    ? 'bg-green-100 text-green-800'
                                    : 'bg-red-100 text-red-800'
                                    "
                                       class="
                                    px-2
                                    flex
                                    text-xs
                                    leading-5
                                    max-w-max
                                    font-semibold
                                    rounded-full
                                    "
                                    >
                                       {{ cases.info.app_type.name }} of {{ cases.info.address.street_address }}
                                       </router-link>
</template>
</td>
<td>
   <div>
      <span
         @click="openModal(client.id)"
         class="text-blue-500 cursor-pointer px-2"
      >Edit</span>
         <span
            @click="deleteStaff(client.id)"
            class="text-gray-500 cursor-pointer px-2"
         >Delete</span>
   </div>
</td>
<td class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-right text-sm
                              font-medium
                              ">
   <router-link
      :to="{ name: 'client', params: { id: client.id } }"
      class="text-blue-600 hover:text-blue-900"
   >
      View->
      </router-link>
</td>
</tr>
</tbody>
</table>
</div>
<Pagination
   @prev-page="handlePageChanged"
   @next-page="handlePageChanged"
   @page-changed="handlePageChanged"
   :total="client.count"
   :current_page="current_page"
/>
</div>
</div>
</div>
<TransitionRoot
   as="template"
   :show="open"
>
   <Dialog
      as="div"
      static
      class="fixed inset-0 overflow-hidden"
      @close="open=false"
      :open="open"
   >
      <div class="absolute inset-0 overflow-hidden">
         <DialogOverlay class="absolute inset-0" />
         <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <TransitionChild
               as="template"
               enter="transform transition ease-in-out duration-500 sm:duration-700"
               enter-from="translate-x-full"
               enter-to="translate-x-0"
               leave="transform transition ease-in-out duration-500 sm:duration-700"
               leave-from="translate-x-0"
               leave-to="translate-x-full"
            >
               <div class="w-screen max-w-md">
                  <div class=" h-full flex flex-col bg-white shadow-xl overflow-y-scroll ">
                     <div class="py-6 px-4 bg-light-blue-600 sm:px-6">
                        <div class="flex items-center justify-between">
                           <DialogTitle class="text-lg font-medium text-white"> Add new user member </DialogTitle>
                           <div class="ml-3 h-7 flex items-center">
                              <button
                                 class=" bg-light-blue-900 rounded-md text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white "
                                 @click="open=false"
                              >
                                 <span class="sr-only">Close panel</span>
                                 <XIcon
                                    class="h-6 w-6"
                                    aria-hidden="true"
                                 />
                                 </button>
                           </div>
                        </div>
                        <div class="mt-1">
                           <p class="text-sm text-blue-300"> Fill in the information below to create a new user member. </p>
                        </div>
                     </div>
                     <form
                        name="insertStaff"
                        id="insertStaff"
                        @submit="handleFormSubmit"
                     >
                        <div class="relative flex-1 py-6 px-4 sm:px-6">
                           <div class="space-y-6 sm:space-y-5">
                              <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                 <label
                                    for="first_name"
                                    class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                 > First Name </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                          type="text"
                                          name="first_name"
                                          id="first_name"
                                          autocomplete="off"
                                          class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                          required
                                       /> </div>
                              </div>
                              <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                 <label
                                    for="last_name"
                                    class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                 > Last Name </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                          type="text"
                                          name="last_name"
                                          id="last_name"
                                          autocomplete="off"
                                          class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                          required
                                       /> </div>
                              </div>
                              <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                 <label
                                    for="username"
                                    class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                 > Username </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                          type="text"
                                          name="username"
                                          id="username"
                                          autocomplete="off"
                                          class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                          required
                                       /> </div>
                              </div>
                              <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                 <label
                                    for="email"
                                    class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                 > E-mail </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                          type="email"
                                          name="email"
                                          id="email"
                                          autocomplete="off"
                                          class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                          required
                                       /> </div>
                              </div>
                              <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                 <label
                                    for="password"
                                    class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                 > Password </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                          type="password"
                                          name="password"
                                          id="password"
                                          autocomplete="off"
                                          class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                          required
                                       /> </div>
                              </div>
                              <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                 <label
                                    for="phone"
                                    class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                 > Phone </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                          type="text"
                                          name="phone"
                                          id="phone"
                                          autocomplete="off"
                                          class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                          required
                                       /> </div>
                              </div>
                              <hr>
                              <h4>Address</h4>
                              <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                                 <label
                                    for="street_address"
                                    class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                 > Street address </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                          type="text"
                                          name="street_address"
                                          id="street_address"
                                          autocomplete="off"
                                          class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                          required
                                       /> </div>
                              </div>
                              <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                 <label
                                    for="city"
                                    class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                 > City </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                          type="text"
                                          name="city"
                                          id="city"
                                          autocomplete="off"
                                          class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                          required
                                       /> </div>
                              </div>
                              <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                 <label
                                    for="state"
                                    class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                 > State </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                          type="text"
                                          name="state"
                                          id="state"
                                          autocomplete="off"
                                          class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                          required
                                       /> </div>
                              </div>
                              <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                 <label
                                    for="country"
                                    class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                 > Country </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                          type="text"
                                          name="country"
                                          id="country"
                                          autocomplete="off"
                                          class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                          required
                                       /> </div>
                              </div>
                              <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                 <label
                                    for="zip"
                                    class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                 > Zip </label>
                                    <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                          type="text"
                                          name="zip"
                                          id="zip"
                                          autocomplete="off"
                                          class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                          required
                                       /> </div>
                              </div>
                           </div>
                        </div>
                        <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                           <button
                              type="button"
                              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
                              @click="open=false"
                           > Cancel </button>
                              <button
                                 type="submit"
                                 class=" ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
                              > Save </button>
                        </div>
                        </form>
                  </div>
               </div>
               </TransitionChild>
         </div>
      </div>
      </Dialog>
      </TransitionRoot>
      <TransitionRoot
         as="template"
         :show="open_edit"
      >
         <Dialog
            as="div"
            static
            class="fixed inset-0 overflow-hidden"
            @close="open_edit=false"
            :open="open_edit"
         >
            <div class="absolute inset-0 overflow-hidden">
               <DialogOverlay class="absolute inset-0" />
               <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                  <TransitionChild
                     as="template"
                     enter="transform transition ease-in-out duration-500 sm:duration-700"
                     enter-from="translate-x-full"
                     enter-to="translate-x-0"
                     leave="transform transition ease-in-out duration-500 sm:duration-700"
                     leave-from="translate-x-0"
                     leave-to="translate-x-full"
                  >
                     <div class="w-screen max-w-md">
                        <div class=" h-full flex flex-col bg-white shadow-xl overflow-y-scroll ">
                           <div class="py-6 px-4 bg-light-blue-600 sm:px-6">
                              <div class="flex items-center justify-between">
                                 <DialogTitle class="text-lg font-medium text-white"> Update staff member </DialogTitle>
                                 <div class="ml-3 h-7 flex items-center">
                                    <button
                                       class=" bg-light-blue-900 rounded-md text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white "
                                       @click="open_edit=false"
                                    >
                                       <span class="sr-only">Close panel</span>
                                       <XIcon
                                          class="h-6 w-6"
                                          aria-hidden="true"
                                       />
                                       </button>
                                 </div>
                              </div>
                              <div class="mt-1">
                                 <p class="text-sm text-blue-300"> Fill in the information below to update. </p>
                              </div>
                           </div>
                           <form
                              name="updateStaff"
                              id="updateStaff"
                              @submit="handleUpdateFormSubmit"
                           >
                              <div class="relative flex-1 py-6 px-4 sm:px-6">
                                 <div class="space-y-6 sm:space-y-5">
                                    <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                       <label
                                          for="first_name"
                                          class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                       > First Name </label>
                                          <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                type="text"
                                                name="first_name"
                                                id="first_name"
                                                autocomplete="off"
                                                class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                                required
                                             /> </div>
                                    </div>
                                    <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                       <label
                                          for="last_name"
                                          class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                       > Last Name </label>
                                          <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                type="text"
                                                name="last_name"
                                                id="last_name"
                                                autocomplete="off"
                                                class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                                required
                                             /> </div>
                                    </div>
                                    <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                       <label
                                          for="username"
                                          class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                       > Username </label>
                                          <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                type="text"
                                                name="username"
                                                id="username"
                                                autocomplete="off"
                                                class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                                required
                                             /> </div>
                                    </div>
                                    <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                       <label
                                          for="email"
                                          class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                       > E-mail </label>
                                          <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                autocomplete="off"
                                                class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                                required
                                             /> </div>
                                    </div>
                                    <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                       <label
                                          for="password"
                                          class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                       > Password </label>
                                          <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                type="password"
                                                name="password"
                                                id="password"
                                                autocomplete="off"
                                                class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                             /> </div>
                                    </div>
                                    <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                       <label
                                          for="phone"
                                          class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                       > Phone </label>
                                          <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                autocomplete="off"
                                                class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                                required
                                             /> </div>
                                    </div>
                                    <hr>
                                    <h4>Address</h4>
                                    <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                                       <label
                                          for="street_address"
                                          class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                       > Street address </label>
                                          <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                type="text"
                                                name="street_address"
                                                id="street_address"
                                                autocomplete="off"
                                                class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                                required
                                             /> </div>
                                    </div>
                                    <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                       <label
                                          for="city"
                                          class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                       > City </label>
                                          <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                type="text"
                                                name="city"
                                                id="city"
                                                autocomplete="off"
                                                class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                                required
                                             /> </div>
                                    </div>
                                    <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                       <label
                                          for="state"
                                          class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                       > State </label>
                                          <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                type="text"
                                                name="state"
                                                id="state"
                                                autocomplete="off"
                                                class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                                required
                                             /> </div>
                                    </div>
                                    <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                       <label
                                          for="country"
                                          class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                       > Country </label>
                                          <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                type="text"
                                                name="country"
                                                id="country"
                                                autocomplete="off"
                                                class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                                required
                                             /> </div>
                                    </div>
                                    <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                                       <label
                                          for="zip"
                                          class=" block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
                                       > Zip </label>
                                          <div class="mt-1 sm:mt-0 sm:col-span-2"> <input
                                                type="text"
                                                name="zip"
                                                id="zip"
                                                autocomplete="off"
                                                class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md "
                                                required
                                             /> </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                                 <button
                                    type="button"
                                    class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
                                    @click="open_edit=false"
                                 > Cancel </button>
                                    <button
                                       type="submit"
                                       class=" ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
                                    > Save </button>
                              </div>
                              </form>
                        </div>
                     </div>
                     </TransitionChild>
               </div>
            </div>
            </Dialog>
            </TransitionRoot>
            </div>
            </template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import {
  ChevronRightIcon,
  HomeIcon,
  SearchIcon,
  FolderAddIcon
} from "@heroicons/vue/solid";
import { XIcon } from "@heroicons/vue/outline";
import { debounce } from "debounce";
import Pagination from "@/components/Pagination";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import axios from "axios";

const pages = [{ name: "Users", href: "#", current: true }];

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ChevronRightIcon,
    HomeIcon,
    SearchIcon,
    Pagination,
    FolderAddIcon,
    XIcon
  },
  setup() {
    const store = useStore();
    const current_page = ref(1);
    const search = ref("");
    const open = ref(false);
    const open_edit = ref(false);
    const updated_id = ref(0);

    store.dispatch("user/getStaffs");

    const handlePageChanged = async start => {
      await store.dispatch("user/getStaffs", { start });
      current_page.value = start;
    };
    const handleFormSubmit = async e => {
      e.preventDefault();
      console.log(e.target.password);
      let form = e.target,
        data = {
          first_name: form.first_name.value,
          last_name: form.last_name.value,
          username: form.username.value,
          email: form.email.value,
          phone: form.phone.value,
          password: form.password.value,
          street_address: form.street_address.value,
          city: form.city.value,
          state: form.state.value,
          country: form.country.value,
          postcode: form.zip.value
        };
      let emailObj = {
        to: data.email,
        subject: "New Account!!",
        name: data.first_name + " " + data.last_name,
        username: data.username,
        email: data.email,
        password: data.password,
        login_url: "ajsolicitors.com/login"
      };
      await store.dispatch("user/createStaff", data);
      await axios.post(
        `${process.env.VUE_APP_API_URL}/emails/staffEmail`,
        emailObj
      );
      open.value = false;
    };
    const handleUpdateFormSubmit = async e => {
      e.preventDefault();
      console.log(e.target.password);
      let form = e.target,
        data = {
          id: updated_id.value,
          first_name: form.first_name.value,
          last_name: form.last_name.value,
          username: form.username.value,
          email: form.email.value,
          phone: form.phone.value,
          password: form.password.value,
          street_address: form.street_address.value,
          city: form.city.value,
          state: form.state.value,
          country: form.country.value,
          postcode: form.zip.value
        };
      await store.dispatch("user/updateStaff", data);
      open_edit.value = false;
    };
    const openModal = async staffId => {
      if (open_edit.value == true) {
        open_edit.value = false;
      } else {
        open_edit.value = true;
        await store.dispatch("user/getStaff", staffId);
        console.log("open_edit", open_edit.value);
        const respData = store.state.user.staff;
        document.getElementById("first_name").value =
          respData.info !== null ? respData.info.first_name || "" : "";
        document.getElementById("last_name").value =
          respData.info !== null ? respData.info.last_name || "" : "";
        document.getElementById("email").value = respData.email;
        document.getElementById("username").value = respData.username;
        document.getElementById("phone").value =
          respData.info !== null ? respData.info.phone || "" : "";
        updated_id.value = staffId;
        document.getElementById("street_address").value =
          respData.info !== null
            ? respData.info.address.street_address || ""
            : "";
        updated_id.value = staffId;
        document.getElementById("city").value =
          respData.info !== null ? respData.info.address.city || "" : "";
        updated_id.value = staffId;
        document.getElementById("state").value =
          respData.info !== null ? respData.info.address.state || "" : "";
        updated_id.value = staffId;
        document.getElementById("country").value =
          respData.info !== null ? respData.info.address.country || "" : "";
        updated_id.value = staffId;
        document.getElementById("zip").value =
          respData.info !== null ? respData.info.address.postcode || "" : "";
        updated_id.value = staffId;
      }
    };
    const deleteStaff = async id => {
      if (confirm("Are you sure?")) {
        await store.dispatch("user/deleteStaff", id);
      }
    };
    return {
      pages,
      open,
      open_edit,
      handlePageChanged,
      current_page,
      search,
      debouncedQuery: debounce(async function() {
        store.dispatch("user/getStaffs", {
          page: 1,
          search: search.value
        });
        current_page.value = 1;
      }, 500),
      client: computed(() => store.state.user.staffs),
      handleFormSubmit,
      handleUpdateFormSubmit,
      openModal,
      deleteStaff
    };
  }
};
</script>